// set print container style
function getStyle() {
  const styleContent = `#print-container {
    display: none;
  }
  @media print {
    body > :not(.print-container) {
      display: none;
    }
    #print-container {
      display: block;
    }
  }`
  const style = document.createElement('style')
  style.innerHTML = styleContent

  return style
}

// clear print container
function cleanPrint() {
  const div = document.getElementById('print-container')
  if (div) {
    document.querySelector('body').removeChild(div)
  }
}

// create new DOM and fill the DOM with the content to be printed
function getContainer(html) {
  cleanPrint()
  const container = document.createElement('div')
  container.setAttribute('id', 'print-container')
  container.innerHTML = html

  return container
}

// call the print method after the image is fully loaded
function getLoadPromise(dom) {
  let imgs = dom.querySelectorAll('img')
  imgs = [].slice.call(imgs)

  if (imgs.length === 0) {
    return Promise.resolve()
  }

  let finishedCount = 0

  return new Promise(resolve => {
    function check() {
      finishedCount += 1
      if (finishedCount === imgs.length) {
        resolve()
      }
    }
    imgs.forEach(img => {
      img.addEventListener('load', check)
      img.addEventListener('error', check)
    })
  })
}

export default function printHtml(html) {
  const style = getStyle()
  const container = getContainer(html)

  document.body.appendChild(style)
  document.body.appendChild(container)

  getLoadPromise(container).then(() => {
    window.print()
    document.body.removeChild(style)
    document.body.removeChild(container)
  })
}
