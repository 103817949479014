<template>
  <v-app>
    <v-sheet class="py-12" color="#F8ECDC" height="100%">
      <v-container>
        <div class="mt-12 mb-6" style="font-size: 30px">
          <span>注文キャンセル</span>
        </div>
        <v-card>
          <v-sheet class="mx-6 my-6 pt-6 pb-3 px-6">
            <div style="font-size: 20px; font-weight: bold" class="mb-8">
              <span>この注文をキャンセルしてもよろしいですか？</span>
            </div>
            <template v-if="apierror.status == 'error'">
              <div v-for="msg of apierror.messages" :key="msg">
                <v-row class="ml-6 mb-3 ma-3">
                  <span style="color: red">* {{ msg }} </span>
                </v-row>
              </div>
            </template>
            <v-row class="px-3" style="background-color: #ebe9e8">
              <v-col cols="12" sm="4" md="2" lg="2">
                <div>
                  <strong>注文日時</strong>
                </div>
                <div>
                  <span>{{ selectedOrder.order_dt }}</span>
                </div>
              </v-col>
              <v-col cols="12" sm="8" md="2" lg="2">
                <div>
                  <strong>合計</strong>
                </div>
                <div>
                  <span
                    >￥{{
                      selectedOrder.order_total
                        ? selectedOrder.order_total.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
                        : ''
                    }}</span
                  >
                </div>
              </v-col>
              <v-col cols="12" sm="7" md="4" lg="5">
                <div>
                  <strong>お届け先</strong>
                </div>
                <div class="mousemove">
                  <span style="color: #ff6e40"
                    >{{ List.addr_name }}<v-icon style="margin-top: -5px">{{ icons.mdiMenuDown }}</v-icon></span
                  >
                  <div class="bottomtip">
                    <span>{{ List.addr_zip }} {{ List.addr_addr1 }} {{ List.addr_addr2 }}</span>
                  </div>
                </div>
              </v-col>
              <v-col cols="12" sm="5" md="4" lg="3">
                <div class="d-flex justify-end text-right">
                  <strong>注文番号：</strong>
                  <span>{{ selectedOrder.order_no }}</span>
                </div>
                <div class="d-flex align-center justify-end" style="font-size: 15px">
                  <div class="print">
                    <span style="text-decoration: underline; color: #ff6e40" @click="openDialog(selectedOrder)"
                      >注文詳細</span
                    >
                  </div>
                  <div>
                    <span class="mx-3"> | </span>
                  </div>
                  <div class="print">
                    <span style="text-decoration: underline; color: #ff6e40" @click="delivery(selectedOrder)"
                      >納品書</span
                    >
                  </div>
                </div>
              </v-col>
            </v-row>
            <v-sheet class="my-6">
              <div v-for="(obj, index) in List.details" :key="index">
                <v-row class="ma-1">
                  <v-col cols="12" sm="4" md="3" lg="2">
                    <v-img :src="obj.image"></v-img>
                  </v-col>
                  <v-col cols="12" sm="8" md="9" lg="10">
                    <div style="font-size: 20px; font-weight: bold">
                      <span>{{ obj.product_name }}</span>
                    </div>

                    <div style="font-size: 16px" class="mt-1">
                      <span>数量：</span>
                      <span>{{ obj.order_qty }}</span>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </v-sheet>
            <v-row class="mb-3">
              <v-spacer></v-spacer>
              <v-col cols="6" sm="3" class="d-flex justify-end">
                <v-btn outlined width="100%" @click="back">
                  <span>戻る</span>
                </v-btn>
              </v-col>
              <v-col cols="6" sm="3" class="d-flex justify-end">
                <v-btn color="#ff6e40" width="100%" depressed @click="doCancel">
                  <span style="color: #fff">注文をキャンセル</span>
                </v-btn>
              </v-col>
            </v-row>
          </v-sheet>
        </v-card>
      </v-container>
    </v-sheet>
    <showDialog :dialog-list="dialogList" :dialog-item="dialogItem" @closeDialog="closeDialog" />
  </v-app>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import printHtml from '@/utils/print'
import { mdiMenuDown } from '@mdi/js'
import showDialog from './components/Dialog.vue'

export default {
  components: {
    showDialog,
  },
  data: () => ({
    icons: {
      mdiMenuDown,
    },
    reason: ['間違えて注文した', '理由なし'],
    List: {},
    dialogList: false,
    dialogItem: '',
    apierror: {
      code: '',
      status: '',
      messages: [],
    },
  }),
  computed: {
    ...mapState('orderStore', ['selectedOrder']),
    ...mapState('deliveryStore', ['selectedDelivery']),
  },
  created() {
    this.loadData()
  },
  mounted() {
    const that = this
    window.onafterprint = function () {
      document.title = that.documentTitle
    }
  },
  methods: {
    ...mapActions('orderStore', ['loadOrder', 'deleteOrder']),
    ...mapActions('deliveryStore', ['loadDelivery']),

    openDialog(item) {
      this.dialogItem = item
      this.dialogList = true
    },
    closeDialog() {
      this.dialogList = false
    },
    delivery(item) {
      this.loadDelivery(item.order_id).then(() => {
        this.documentTitle = document.title
        const currentTime = new Date(Date.now())
        document.title = `${
          item.order_id
        }-${currentTime.getFullYear()}${currentTime.getMonth()}${currentTime.getDay()}${currentTime.getHours()}${currentTime.getMinutes()}${currentTime.getSeconds()}`
        if (this.selectedDelivery.html) {
          const printData = this.selectedDelivery.html
          printHtml(printData)
        }
      })
    },
    loadData() {
      this.List = {
        ...this.selectedOrder,
      }
      this.List.details.forEach((il, index) => {
        if (il.images) {
          this.List.details[index].image = `${il.imgUrl_prefix}/${il.images
            .replaceAll('"', '')
            .split(',')[0]
            .replace(/^\//g, '')}`
        }
      })
    },

    doCancel() {
      this.deleteOrder(this.selectedOrder.order_id)
        .then(() => {
          this.back()
        })
        .catch(error => {
          this.apierror.status = 'error'
          this.apierror.code = error.response.data.code
          this.apierror.messages = error.response.data.message
        })
    },
    back() {
      this.$router.push({
        path: '/history',
        query: {
          value: 'undispatched',
        },
      })
    },
  },
}
</script>

<style scoped>
.v-text-field--outlined >>> fieldset {
  border-color: #ff6e40;
  border-width: 2px;
}
.print :hover {
  cursor: pointer;
}
.bottomtip {
  display: none;
}
.mousemove :hover {
  cursor: pointer;
  text-decoration: underline;
}
.mousemove:hover .bottomtip {
  display: block;
}
</style>
