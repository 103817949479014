<template>
  <v-dialog v-model="dialog" width="900px" persistent>
    <v-card class="pb-6">
      <div class="px-6 pt-6">
        <div class="mb-3" style="font-size: 20px; font-weight: bold">
          <span>注文の詳細</span>
        </div>
        <v-card class="mt-6" outlined color="rgb(0, 0, 0, 0)">
          <v-row class="px-3 py-1" style="background-color: #ebe9e8">
            <v-col cols="12" sm="4">
              <div>
                <strong>注文日：</strong> <span>{{ item.order_dt }}</span>
              </div>
              <div class="my-3">
                <strong>注文番号：</strong>
                <span>{{ item.order_no }}</span>
              </div>
              <div>
                <strong>お届け先：</strong>
              </div>
              <div>
                <span
                  >{{ item.addr_name }} <br />{{ item.addr_zip }} <br />{{ item.addr_addr1 }} <br />{{
                    item.addr_addr2
                  }}</span
                >
              </div>
            </v-col>
            <v-col cols="12" sm="8">
              <v-row style="font-weight: bold; font-size: 15px">
                <v-col class="d-flex justify-start">
                  <span>商品名</span>
                </v-col>
                <v-col class="d-flex justify-end">
                  <span>税込金額</span>
                </v-col>
              </v-row>
              <v-row v-for="(obj, index) in item.details" :key="index" style="font-size: 15px">
                <v-col class="d-flex justify-start" cols="6" sm="8">
                  {{ obj.product_name }}
                </v-col>
                <v-col class="d-flex justify-end" cols="6" sm="4">
                  {{ obj.order_qty }}x ￥{{ obj.order_tip.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,') }}
                </v-col>
              </v-row>
              <v-row style="font-size: 15px">
                <v-col class="d-flex justify-start"> 送料 </v-col>
                <v-col class="d-flex justify-end">
                  ￥{{ item.shipping_fee.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,') }}
                </v-col>
              </v-row>
              <v-row v-if="item.coupon_discount !== 0" style="font-size: 15px">
                <v-col class="d-flex justify-start"> 小計 </v-col>
                <v-col class="d-flex justify-end">
                  ￥{{
                    total()
                      .toString()
                      .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
                  }}
                </v-col>
              </v-row>
              <v-divider v-if="item.coupon_discount !== 0" class="my-4"></v-divider>
              <div v-if="item.coupon_discount !== 0">
                <v-row style="font-size: 15px">
                  <v-col class="d-flex justify-start"> 割引金額 </v-col>
                  <v-col class="d-flex justify-end" style="color: red">
                    -￥{{ item.coupon_discount.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,') }}
                  </v-col>
                </v-row>
              </div>
              <v-divider class="my-4"></v-divider>
              <v-row style="font-size: 15px">
                <v-col class="d-flex justify-start"> 合計（税込） </v-col>
                <v-col class="d-flex justify-end" style="font-size: 18px; font-weight: bold">
                  ￥{{ item.order_total.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,') }}
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
      </div>
      <div class="d-flex justify-center mt-8">
        <v-btn color="#ff6e40" width="80px" depressed @click="closeDialog">
          <span style="color: #fff">OK</span>
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: ['dialogList', 'dialogItem'],
  data: () => ({
    dialog: false,
    item: {
      addr_addr1: '',
      addr_addr2: '',
      addr_name: '',
      addr_zip: '',
      coupon_discount: 0,
      details: [
        {
          detail_id: '',
          image: '',
          images: '',
          imgUrl_prefix: '',
          order_qty: 0,
          order_tip: 0,
          product_code: '',
          product_name: '',
        },
      ],
      mbr_name: '',
      order_dt: '',
      order_id: '',
      order_no: '',
      order_total: 0,
      os_code: '',
      shipping_fee: 0,
    },
  }),
  watch: {
    dialogList() {
      this.dialog = this.dialogList
      this.item = this.dialogItem
    },
  },
  methods: {
    openDialog(item) {
      this.item = item
      this.dialog = true
    },
    closeDialog() {
      this.dialog = false
      this.$emit('closeDialog')
    },
    total() {
      let price = 0

      price = this.item.order_total - this.item.coupon_discount

      return price.toFixed(0)
    },
  },
}
</script>
